@import 'includes';

// Unihall styles
.panel {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border: $card-border;
  background: $white;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: .5rem 0;
  &:hover {
    text-decoration: none;
    box-shadow: 0 14px 14px -14px rgba(0,0,0,0.1);
    .panel-image {
      filter: contrast(100%);
    }
  }
  &-content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: $global-padding*1.5;
  }
  &-image {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 10em;
    filter: contrast(70%);
    //filter: saturate(180%);
    overflow: hidden;
    position: relative;
    transition: filter 0.5s cubic-bezier(.43,.41,.22,.91);;
    &::before {
      content: "";
  	  display: block;
      padding-top: 56.25%; // 16:9 aspect ratio
    }
    @include breakpoint(medium) {
      height: 12em;
      &::before {
        padding-top: 66.6%; // 3:2 aspect ratio
      }
    }
    img {
      height: auto;
      max-width: 100%;
      vertical-align: middle;
    }
  }
  &-date {
    background: $unihall-blue;
    color: $white;
    font-size: .9rem;
    font-weight: 500;
    width: 13em;
    letter-spacing: .5px;
    text-transform: uppercase;
    margin-bottom: $global-margin/1.5;
    padding: $global-padding/4 $global-padding/4 $global-padding/4 $global-padding;
  }
  &-title {
    color: $unihall-blue;
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: .1px;
    line-height: 1.2;
  }
  &-text {
    flex: 1 1 auto;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1.25rem;
    color: $dark-gray;
  }
  &-footer {
    font-size: .75rem;
    font-weight: 600;
    color: $darkest-gray;
    text-transform: uppercase;
    margin-top: $global-margin;
  }
  // horizontal events panels styles
  &-horizontal {
      flex-direction: row;
      min-height: 10em;
      margin: .5rem;
      .panel {
        &-image {
        width: 18em;
        height:100%;
      }
      &-date {
        background: $unihall-pink;
      }
      &-title {
        color: $unihall-pink;
      }
      &-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 0;
        margin-top: 0;
        width: 18em;
        @include breakpoint('medium') {
          width: 35em;
        }
        @include breakpoint('large') {
          width: 30em;
        }
      }
    }
  }
}


// module background color styling
.white-bg {
  .panel {
    background: $lightest-gray;
  }
}

.dark-bg {
  .panel {
    &:hover {
      box-shadow: 0 14px 14px -14px rgba(0,0,0,0.6);
    }
  }
}
